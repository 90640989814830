<template>
  <!-- Category search -->
  <el-dialog
    title="Нийлүүлэгч сонгох"
    :visible.sync="show"
    @close="closeDialog"
    width="600px"
  >
    <div>
      <el-select
        placeholder="Говь, Гоёо..."
        v-model="filterText"
        @change="selectChange"
        style="width: 100%"
        filterable
      >
        <el-option
          v-for="(item, index) in suppliers"
          :key="index"
          :label="item.supplier_monName"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">Cancel</el-button>
      <el-button type="success" @click="returnSuppliers">
        Харилцагч сонгох
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import services from '../../../../helpers/services'
export default {
  name: 'supplierSearchDialog',
  props: ['visibleSupplierDialog'],
  mounted () {
    this.getSuppliers()
  },
  data () {
    return {
      filterText: null,
      supplierTempValue: null,
      loading: false,
      suppliers: [],
      show: this.visibleSupplierDialog
    }
  },
  methods: {
    selectChange (item) {
      this.filterText = item.supplier_monName
      this.supplierTempValue = item
    },
    onFocusTest () {
      console.log('jkaslasfjlksfajl')
    },
    getSuppliers () {
      const query = ''
      services.getSuppliers(query).then((response) => {
        this.suppliers = response.datas
        this.loading = false
      })
    },
    closeDialog () {
      this.$emit('update:visibleSupplierDialog', false)
    },
    returnSuppliers () {
      const tempSuppliers = [this.supplierTempValue]
      if (tempSuppliers.length > 0) {
        this.$emit('update:suppliers', tempSuppliers)
        this.show = false
      } else {
        this.$notify.error({
          title: 'Oops',
          position: 'bottom-left',
          message: 'Нийлүүлэгч сонгоно уу',
          duration: 2000
        })
      }
    }
  }
}
</script>
