<template>
  <!-- User group search -->
  <el-dialog
    title="Хэрэглэгчид"
    :visible.sync="show"
    @close="closeDialog"
    width="600px"
  >
    <el-table
    :data="brands"
    style="width: 100%"
    v-loading="loading"
    :show-header="false"
    >
      <el-table-column label="Нэвтрэх нэр" align="center" prop="Username"></el-table-column>
      <el-table-column label="Төлөв" align="center" prop="UserStatus"></el-table-column>
      <el-table-column label="И-мэйл хаяг" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.Attributes[0].Value}}</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <el-check-box v-model="scope.row.checked"></el-check-box>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">Cancel</el-button>
      <el-button type="success" @click="returnBrands">
        Хэрэглэгч сонгох
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import services from '../../../../helpers/services'
export default {
  name: 'userSearchDialog',
  props: [
    'visibleUserDialog'
  ],
  data () {
    return {
      filterText: '',
      loading: false,
      brands: [],
      show: this.visibleUserDialog
    }
  },
  created () {
    this.getUserGroups()
  },
  methods: {
    getUserGroups () {
      this.loading = true
      services.getUsers().then(response => {
        if (Array.isArray(response.datas) && response.datas.length > 0) {
          response.datas.forEach(brand => {
            const property = {
              checked: false
            }
            Object.assign(brand, property, brand)
          })
          this.brands = response.datas
        }
        this.loading = false
      })
    },
    closeDialog () {
      this.$emit('update:visibleUserDialog', false)
    },
    returnBrands () {
      const tempBrands = [...this.brands.filter(brand => brand.checked)]
      if (tempBrands.length > 0) {
        this.$emit('update:users', tempBrands)
        this.show = false
      } else {
        this.$notify.error({
          title: 'Oops',
          position: 'bottom-left',
          message: 'Нийлүүлэгч сонгоно уу',
          duration: 2000
        })
      }
    }
  }
}
</script>
